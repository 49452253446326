import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "./Icon";
import { StaticImage } from "gatsby-plugin-image";

const BrandList = ({ item, count, isReview }) => {

  let bonus = item.casinoInformation.bonus;
  let freespins = item.casinoInformation.freespins;
  let rating = item.casinoInformation.rating;
  let convertedRating = parseInt(rating)
  let terms = item.casinoInformation.disclaimer ? item.casinoInformation.disclaimer : undefined;
  let affiliateLink = item.affiliateLink.affiliateLink ? item.affiliateLink.affiliateLink.uri : undefined;
  let bonusPercent = item.casinoInformation.bonuspercent ? item.casinoInformation.bonuspercent : "100%";
  let betalningslosningar = item.casinoInformation.betalningslosningar ? item.casinoInformation.betalningslosningar : undefined;
  let ribbon = item.casinoInformation.ribbon ? item.casinoInformation.ribbon.toLowerCase() : undefined;

  const featuredImage = {
    data: item.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: item.featuredImage?.node?.altText || ``,
  }

  return (
    <Article>
      <Brand className="row">
        <Image>
          {featuredImage?.data && (
            <GatsbyImage
              image={featuredImage.data}
              alt={featuredImage.alt}
              style={{ 
                width: 200,
                height: 120,
                borderRadius: 4
              }}
            />
          )}
        </Image>
        <WelcomeOffer>
          <div className="column">
            <div className="left">
              {!isReview ? (
                <Link to={item.uri}>
                  <span className="count">{count + 1}.</span>{item.title}
                </Link>
                ) : (
                <span className="singleTitle">{item.title}</span>
              )}
              {new Array(convertedRating).fill(null).map(() => (
                <Icon icon="star-full" size={14} />            
              ))}
              {new Array(rating).fill(null).map(() => (
                rating.indexOf('.') !== -1 && (
                  <Icon icon="star-half" size={14}  />            
                )
              )                  
              )}
            </div>
            {ribbon && ribbon.includes("bonus") && (
              <span className="ribbon">{item.casinoInformation.ribbon}</span>
            )}
            {ribbon && ribbon.includes("nytt") && (
              <span className="ribbon green">{item.casinoInformation.ribbon}</span>
            )}
          </div>
     
          {bonus && bonus.length > 2 && ( 
            <div>
              {bonus === "Ingen"
                ? <p>Ingen bonus</p>
                : <div>
                  <p>{bonus} bonus</p> 
                  {freespins && ( 
                    <div>
                      {((freespins === "Inga" || freespins === "0") || 
                      (freespins === "0 " || freespins === "0  ")) ? null 
                      : <p className="freespins">+ {freespins} freespins</p>}
                    </div>
                  )}
                  <span className="info">Bonusen är {bonusPercent} upp till {bonus} </span>
                </div>
              }
            </div>
          )}
          {freespins && bonus && bonus.length < 3 && ( 
            <div>
              {((freespins === "Inga" || freespins === "0") || 
              (freespins === "0 " || freespins === "0  ")) ? null 
              : <p> {freespins} freespins</p>}
            </div>
          )}
          {freespins && !bonus && ( 
            <div>
              {((freespins === "Inga" || freespins === "0") || 
              (freespins === "0 " || freespins === "0  ")) ? null 
              : <p> {freespins} freespins</p>}
            </div>
          )}
        </WelcomeOffer>
          <BrandDescription className="brandDescription">
            <span><Icon icon="check-circle" size={16} /> {item.casinoInformation.description}</span>
            <span><Icon icon="check-circle" size={16} />{item.casinoInformation.description2}</span>
            <span><Icon icon="check-circle" size={16} />{item.casinoInformation.description3}</span>
            <span><StaticImage src="../sv_SE.webp" width={80} height={80} alt="Casino med svensk licens" />Casinot har svensk licens</span>
          </BrandDescription>
        
        <div className="buttonWrapper">
          <Button>
            <a target="_blank" rel="nofollow noreferrer noopener" href={affiliateLink}>
              <span>Till casinot</span>
            </a>
          </Button>
          {betalningslosningar && (
            <div className="paymentproviders">
              <img load="lazy" width="40" height="40" alt="BankID" src="../bankid.png" />
              {betalningslosningar.map((item) => {  
                const image = "/" + item.toLowerCase() + ".png"; 

                return (
                  <img src={image} alt={item} width="40" height="40" load="lazy" />
                )
              })}
            </div>
          )}
        </div>
      </Brand>
      <div className="terms">        
        <p>18+ | Spela ansvarfullt | <a href="https://www.stodlinjen.se" rel="nofollow noreferrer noopener" target="_blank">Stödlinjen.se</a> | <a href="https://www.spelpaus.se/" rel="nofollow noopener noreferrer" target="_blank">Spelpaus.se</a> | 
        {terms ? (
        <a href={terms} rel="nofollow noopener noreferrer" target="_blank"> Regler och villkor gäller</a>
        ) : ( <span> Regler och villkor gäller</span>
        )}
        </p>
        </div>
    </Article>
  );
};

// Connect the Item to gain access to `state` as a prop
export default BrandList;

const Article = styled.div`
  .terms {
    background: #fff;
    font-size: 9px;
    padding: 9px 12px;
    color: #73768a;
    margin: -15px 0 10px 0px;
    background-color: #fff;
    border-top: solid 1px #e6eaf5;
    border-radius: 0 0 8px 8px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

    p {
      font-size: 8px;
      font-style: italic;
      margin: 0;
      color: #595d75;
    }   
    
    a {
      text-decoration: none;

      strong {
        font-weight: 400;
      }
    }
  }

  .thumbnail {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const Brand = styled.div`
  display: grid;
  grid-template-columns: .7fr .9fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  margin: 15px 0;
  background: #fff;
  box-shadow: 0 2px 3px -1px rgb(3 15 39 / 12%);

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;  
  }

  .buttonWrapper {
    text-align: center;
    margin-right: 20px;
    margin-top: 15px;

    @media (max-width: 600px) {
      margin: 15px 15px 0;
      grid-column-start: 1;
      grid-column-end: span col4-start;
      grid-row-start: 2;
      grid-row-end: span 2;
    }

    .paymentproviders {
      display: flex;
      justify-content: center;
      grid-gap: 10px;
      margin: 15px;

      img {
        width: 20px;
        height: 20px;
        border-radius: 50px;
      }
    }
  }

  a {
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    line-height: 1;

    @media (max-width: 600px) {
      font-size: 16px !important; 
    }
  }

  .freespins {
    font-size: 14px;
    margin-top: 2px;
    color: #000;
  }

  p {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
    color: #00852c;
    line-height: 1.2;
    margin-top: 3px;
    letter-spacing: -0.02em;

    @media (max-width: 992px) {
      margin-top: 0;
    }
  }
`;

const WelcomeOffer = styled.div`

  @media (max-width: 992px) {
    padding-right: 15px;
    padding-top: 3px;
  }

  a {
    margin-bottom: -2px;
    display: block;

    @media (max-width: 600px) {
      margin-bottom: -5px;
      font-size: 14px !important;
    }
  }

  .count {
    font-weight: 500;
    display: inline-block;
    font-size: 15px;
    color: #8f98a7;
    margin-right: 3px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .singleTitle {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #000;
    display: block;
    margin-bottom: -8px;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  span {
    display: flex;
    align-items: center;
    font-weight: 600;

    p {
      margin: 0;
      font-size: 15px;
      padding-right: 3px;
      color: #99a1aca6;
      font-weight: 500;
    }
  }

  .column {
    display: flex;
    align-items: baseline;
    
    .ribbon {
      display: block;
      font-size: 12px;
      background: #f5f5f5;
      padding: 2px 12px;
      border-radius: 12px;
      text-transform: uppercase;
      margin-left: 15px;
      color: #000;

      @media (max-width: 600px) {
        display: none;
      }

      &.green {
        background: var(--color-yellow);
      }
    }
  }

  svg {
    fill: #fc0 !important;
    margin: 0 1px;

    @media (max-width: 600px) {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .info {
    font-size: 12px;
    color: #657085b8;
    display: block;
    margin-top: 4px;
    font-weight: var(--fontWeight-medium);

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
`;

const BrandDescription = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    padding: 0 15px 15px;
  }

  @media (max-width: 600px) {
    display: none;
  }

  img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  span {
    margin: 3px 0;
    display: flex;
    font-size: 13px;
    align-items: center;
    font-weight: 400;
    color: #000;

    @media (max-width: 992px) {
      font-size: 11px;
    }

    svg {
      color: #58b224;
      margin: 0 8px 0 0;
      font-size: 18px;

      @media (max-width: 992px) {
        margin: 0 6px 0 0;
      }
    }
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    background: green;
    color: #fff;
    font-size: 18px;
    padding: 20px;
    border-radius: 8px;
    justify-content: center;
    background: #00852c;
    line-height: 1.2;
    display: flex;
    width: 100%;
    font-weight: 700;

    @media (min-width: 992px) {
      min-width: 200px;

      &:hover {
        background: #079b37;
      }
    }

    @media (max-width: 992px) {
      font-size: 14px;
      padding: 18px 15px;
      margin-top: -12px;
    }
  }
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
  width: 200px;

  img {
    border-radius: 4px !important;
  }

  @media (max-width: 600px) {
    width: 145px;
  }
`;

